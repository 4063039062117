<template>
    <front-layout>
        <v-container fluid class="page__top-line">
            <v-row>
                <v-col
                    cols="12"
                    md="7"
                    lg="8"
                    class="pl-8 pl-lg-16 pr-8 pb-9 venuePrimary--bg white--text"
                >
                    <h1 class="main__head-1 mb-4">Venue Connection</h1>
                    <div class="main__text-intro pl-sm-8 mb-8">
                        <p>Ready to make your restaurant the hottest local spot?</p>
                        <p>We know two things about humans:</p>
                        <ul>
                            <li>They love good food</li>
                            <li>They love good music</li>
                        </ul>
                        <p>If you have a kickin’ menu, use GrubTunes to book local artists and musicians.</p>
                        <p>It’s the easiest way to find out who’s trending and get them into your restaurant or venue for a jam-packed show. If you want to be the place to be, you need to grab a GrubTunes account now!</p>
                    </div>
                    <div v-if="!venuesLoading" class="pl-sm-8">
                        <h3 class="main__head-3 mb-4">Featured Venues</h3>
                        <v-container fluid class="pa-0">
                            <v-row>
                                <v-col
                                    sm="6"
                                    md="12"
                                    lg="6"
                                    class="pr-lg-8"
                                    v-for="(venue,i) in featuredVenues"
                                    :key="`venue-${i}`"
                                >
                                    <v-sheet
                                        white
                                        rounded="lg"
                                        class="main__featured-card darkGrayishBlue--text"
                                    >
                                    <router-link :to="{name: 'venue.public', params: {slug: venue.slug}}">
                                        <v-container fluid>
                                            <v-row align="center">
                                                <v-col cols="6">
                                                    <v-img
                                                        :src="venue.imgUrl"
                                                        height="100%"
                                                        min-height="90px"
                                                    ></v-img>
                                                </v-col>
                                                <v-col cols="6">
                                                    <h4 class="mb-2">{{ venue.name }}</h4>
                                                    <p class="mb-0">{{ venue.address }}</p>
                                                </v-col>
                                                <v-col cols="12" class="pt-0">
                                                    {{ venue.text }}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </router-link>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="5"
                    lg="4"
                    class="main__advantages veryDarkPink white--text pa-10"
                >
                    <div class="mx-auto" style="width: fit-content;">
                        <h2 class="main__head-2 mb-10">Sign Up Now for Free!</h2>
                        <ul class="mb-16">
                            <li
                                v-for="(advantage,i) in advantages"
                                :key="`advantage-${i}`"
                            >
                                {{ advantage }}
                            </li>
                        </ul>
                        <v-btn
                            :disabled="$auth.user()"
                            :to="{name: 'sign-up', query: { role: 'venue' }, hash: '#signupForm'}"
                            block
                            x-large
                            class="white veryDarkPink--text rounded-lg py-8"
                        >
                            Sign Up Now!
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <plans-block type="venue"/>

    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Venue from "@/models/Venue";
import PlansBlock from "@/components/plans/PlansBlock";

export default {
    name: "VenueLanding",
    components: {FrontLayout, PlansBlock},
    computed: {
        featuredVenues() {
            if (!this.venues.length) {
                return []
            }

            return [
                this.getVenueData(0),
                this.getVenueData(1),
            ]
        }
    },
    data: function () {
        return {
            venuesLoading: false,
            venues: [],
            advantages: [
                'Be found by new customers',
                'Connect with local artists and musicians',
                'Book musicians right on the platform',
                'Favorite the talent you like most',
                'Advertise your restaurant and menu',
                'Have fans sign up for events at your place, then get reminders to show up',
                'Become a featured venue',
            ],
        }
    },
    async mounted() {
        this.venuesLoading = true
        await this.fetchVenues()
        this.venuesLoading = false
    },
    methods: {
        async fetchVenues() {
            const venues = await Venue.custom('venues/public').orderBy('-id').limit(2).get();
            this.venues = venues.data
        },
        getVenueData(i) {
            return {
                name: this.venues[i].name,
                slug: this.venues[i].slug,
                address: this.venues[i].address,
                text: this.venues[i].description,
                imgUrl: this.venues[i].featured_image,
            }
        }
    },
}
</script>

<style scoped>
</style>
